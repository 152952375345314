<template>
  <div class="header_" :style="NavType == 1
    ? 'background:rgba(255,255,255,.8);'
    : 'background:rgba(255,255,255,0)'
    ">
    <div>
      <div class="nvamo">
        <div class="KS" @click="isActiveclick">
          <el-icon :class="{ no: !isActive }">
            <Expand />
          </el-icon>
          <el-icon :class="{ no: isActive }">
            <Close />
          </el-icon>
        </div>
        <!-- <div>
          <el-icon><CloseBold /></el-icon>
        </div> -->
      </div>
      <div class="header_left">
        <span class="header_left_login">
          <!-- <img @click="
            router.push({
              path: '/',
            })
            " :src="NavType == 1
      ? require('@/assets/herder_logo.png')
      : require('@/assets/herder_logo1.png') -->
          <!-- " /> -->
          <img @click="
            router.push({
              path: '/',
            })
            " :src="require('@/assets/herder_logo.png')" alt="" />
        </span>
        <!-- nav -->
        <div class="header_left_nav">
          <div v-for="index in navList" :class="index.class" :key="index" @click.stop="navClick(index.url, index)">
            <span :style="{
              color:
                index.class == 'header_nav_title header_nav_title_y'
                  ? '#C01A26'
                  : NavType == 2
                    ? '#fff'
                    : '#3a3a3a',
            }">{{ index.title }}</span>

            <div v-if="index.list" class="nav_m_list">
              <div v-for="index2 in index.list" :key="index2" @click.stop="navListClick(index2, index)"
                class="nav_m_list_box">
                <img :src="index2.img" style="margin-right: 0.02rem" /><span>{{
                  index2.title
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header_right">
        <!-- 会员充值 -->
        <!-- <div class="header_right_hycz" @click="handTopUpMember">
					<img src="../../assets/publick_huiyuan3.png" />
					会员充值
				</div> -->
        <!-- 消息提示 -->
        <div style="position: relative">
          <!-- {{stopPressChecked}} -->
          <div v-if="stopPressChecked == true"
            style="width: 0.1rem;height: 0.1rem;border-radius: 50%;background: red;position: absolute;top: 0.09rem;right: 0.21rem;}">
          </div>
          <!-- @mouseenter="stopPressListMouseenter" -->
          <div></div>
          <img @mouseover="stopPressListMouseenter" @mouseleave="stopPressListMouseleave" class="header_right_xxts cur"
            :src="NavType != 1
              ? require('@/assets/publick_xiaoxiB.png')
              : require('@/assets/publick_xiaoxi.png')
              " />
          <div v-if="meaasgeTitle_list_xttz.length" class="header_right_xxts_text"></div>
          <!-- 消息列表 -->
          <div v-if="stopPressList == true && publick_checked_boor == 'xxlxs'" @mouseenter="stopPressListMouseenter"
            @mouseleave="stopPressListMouseleave" class="header_right_messageList">
            <div class="header_right_messageListUser">
              <div v-if="meaasgeTitle_list_xttz.length" v-for="(item, index) in meaasgeTitle_list_xttz" :key="index">
                <template style="display: flex; margin-bottom: 0.24rem" v-for="(link, key) in detailLinks" :key="key">
                  <!-- {{ (key, item.code) }} -->
                  <div style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      cursor: pointer;
                      margin-bottom: 0.24rem;
                    " v-if="key === item.code">
                    <img class="xt-img" :src="item.avatar1
                      ? item.avatar1
                      : require(`@/assets/Frame33.png`)
                      " />
                    <div style="width: 80%; padding: 0 10px" @click="goToDetail(link.path, link.query, item, index)">
                      <p class="MTXL_time">
                        {{
                          item.nickName1
                          ? `${item.nickName1 + "" + item.createTime}`
                          : `系统通知${item.createTime}`
                        }}
                      </p>
                      <p class="MTXL_title">
                        {{
                          item.noticeTitle
                          ? item.noticeTitle
                          : item.noticeContent
                        }}
                      </p>
                    </div>
                  </div>
                </template>
              </div>
              <div v-else style="text-align: center; position: relative">
                <img style="width: 100%; position: relative" src="@/assets/zt_xttz.png" />
                <div style="
                    position: absolute;
                    top: 72%;
                    left: 50%;
                    color: #ccc;
                    transform: translate(-50%, -50%);
                  ">
                  暂无消息
                </div>
              </div>
            </div>
            <div class="header_right_messageListButton">
              <div>
                <span @click="HRU_list_qbyd">全部已读</span>
                <!--  -->
                <span @click="HRU_list_ckqb">查看所有 ></span>
              </div>
            </div>
          </div>
        </div>
        <!-- 登录/个人设置 - 按钮 -->
        <!-- localStorage.getItem('token') -->
        <div>
          <div v-if="!loginState" class="header_right_login" @click="loginClick"
            :style="NavType != 1 ? 'background:#fff;' : ''">
            <span class="cur">登录</span>
          </div>
          <div v-if="loginState" class="header_right_login" style="position: relative">
            <!-- @click="goUser" -->
            <img style="width: 100%; height: 100%; border-radius: 50%"
              :src="avatar ? avatar : require('@/assets/logo.png')" @click="LoggedClick" @mouseout="LoggedMouseout"
              @mouseover="LoggedClick" />
            <!-- :src="avatar ? avatar : require('../../assets/logo.png')" @click="LoggedClick" @mouseout="LoggedMouseout" -->
            <div v-if="personalSetting_checked == true &&
              publick_checked_boor == 'grsz'
              " class="header_right_login_personalSetting" @mouseout="LoggedMouseout" @mouseover="LoggedClick">
              <div class="HRLPS_t">
                <div class="HRLPS_tl" @click="goUser">
                  <img class="HRLPS_tl_headPortrait" :src="avatar ? avatar : require('../../assets/logo.png')" />
                  <div class="HRLPS_tl_title">
                    <p class="HRLPS_tl_title_name">
                      <span>{{ nickNameu }} </span>
                      <img class="cur" style="width: 0.16rem" src="../../assets/Frame@2x(8).png" />
                    </p>
                    <p class="HRLPS_tl_title_occupation" v-if="profess">
                      {{ profess }}
                    </p>
                  </div>
                </div>
                <img class="HRLPS_tl_logOut" @click="loLout()" src="../../assets/grzx_tcdl.png" />
              </div>
              <!-- 用户数据统计 -->
              <!-- 11111 {{ userList }}1111 -->
              <div class="HRLPS_t HRLPS_t_statistics">
                <div @click="goUser">
                  <p class="HRLPS_t_statistics_number">
                    {{
                      userList
                      ? userList.workNumber
                        ? userList.workNumber
                        : 0
                      : "0"
                    }}
                  </p>
                  <p class="HRLPS_t_statistics_title">案例</p>
                </div>
                <div @click="VermicelliClick">
                  <p class="HRLPS_t_statistics_number">
                    {{
                      userList
                      ? userList.fansNumber
                        ? userList.fansNumber
                        : 0
                      : "0"
                    }}
                  </p>
                  <p class="HRLPS_t_statistics_title">粉丝</p>
                </div>

                <div @click="skip">
                  <p class="HRLPS_t_statistics_number">
                    {{
                      userList
                      ? userList.signingNumer
                        ? userList.signingNumer
                        : 0
                      : "0"
                    }}
                  </p>
                  <p class="HRLPS_t_statistics_title">签单</p>
                </div>
                <div @click="FollowClick">
                  <p class="HRLPS_t_statistics_number">
                    {{
                      userList
                      ? userList.followNumber
                        ? userList.followNumber
                        : 0
                      : "0"
                    }}
                  </p>
                  <p class="HRLPS_t_statistics_title">关注</p>
                </div>
              </div>
              <div class="HRLPS_t_iconList">
                <div class="cur" v-for="index in my_list" :key="index" @click="my_list_click(index)">
                  <img :src="index.img" />
                  <p>{{ index.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 上传按钮 -->
        <div style="position: relative">
          <div @mouseenter="header_right_uploadingMouseover()" @mouseleave="header_right_uploadingMouseout()"
            class="header_right_uploading cur" :style="NavType != 1 ? 'background:#fff;color:#C01A26;' : ''">
            上传
          </div>
          <!-- 上传方式 -->
          <div @mouseenter="header_right_uploadingMouseoverList()" @mouseleave="header_right_uploadingMouseout()" v-if="header_right_uploading_listBoor == true &&
            publick_checked_boor == 'scfs'
            " class="header_right_uploading_list">
            <div class="cur" v-for="index in HRU_list" :key="index"
              :style="index.checked == true ? 'background:#F0F1F1;' : ''" @mouseenter="HRU_listMouseover(index)"
              @mouseleave="HRU_listMouseout(index)" @click="HRU_listClick(index)">
              <img :src="index.img" />
              {{ index.title }}
            </div>
          </div>
        </div>
        <!-- 退出登录-->
        <!-- <div style="position:relative;">
				</div> -->
        <!-- 会员充值弹框 -->
        <el-dialog v-model="topUpMember" title="" class="member_box" width="10rem" :close-on-click-modal="false">
          <div>
            <div class="member_top">
              <div style="width: 0.5rem; height: 0.5rem; border-radius: 50%">
                <img style="width: 100%; height: 100%" src="../../assets/logo.png" />
              </div>
              <div class="member_top_name" style="">充值帐号：王家卫</div>
              <div class="member_top_line"></div>
              <div class="member_top_price">
                <div class="member_top_price_item">
                  <div>充值余额</div>
                  <div>32465</div>
                </div>

                <div class="member_top_price_item">
                  <div>标识币</div>
                  <div>32465</div>
                </div>

                <div class="member_top_price_item">
                  <div>账户折扣</div>
                  <div>32465</div>
                </div>
              </div>
            </div>
            <div class="newMember_tab" :style="memberTab == 1
              ? 'background: linear-gradient(180deg, #FFB9A0 0%, #FDA789 100%);'
              : 'background: linear-gradient(180deg, #5C4947 0%, #2F2322 100%);'
              ">
              <div @click="memberTabClick(1)" style="left: -0.15rem">
                <div :class="memberTab == 1 ? 'nm_tab_back1' : 'nm_tab_back1Y'"></div>
                <div class="newMember_tab_title">
                  <span class="newMember_tab_titleS" :style="memberTab == 1
                    ? 'color: #5E2D11;font-size: 0.2rem;'
                    : 'color: #5E2D11;font-size: 0.2rem;'
                    ">账户充值</span>
                  <span :style="memberTab == 1
                    ? 'color: #333333;font-size: 0.14rem;'
                    : 'color: #333333;font-size: 0.14rem;'
                    ">享正版海量素材</span>
                </div>
              </div>
              <div @click="memberTabClick(2)" style="right: -0.15rem">
                <div :class="memberTab == 2 ? 'nm_tab_back2' : 'nm_tab_back2Y'"></div>
                <div class="newMember_tab_title">
                  <span class="newMember_tab_titleS" :style="memberTab == 2
                    ? 'background: linear-gradient(116deg, #FCE5CC 0%, #F7CEBA 76%, #F5BD98 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;font-size: 0.2rem;'
                    : 'color: #5E2D11;font-size: 0.2rem;'
                    ">大额充值</span>
                  <span :style="memberTab == 2
                    ? 'color: #FFFFFF;font-size: 0.14rem;'
                    : 'color: #333333;font-size: 0.14rem;'
                    ">享企业授权折扣</span>
                </div>
              </div>
            </div>
            <!-- 账号充值 -->
            <div class="member_tab_1" v-if="memberTab == 1">
              <div style="
                  color: #333333;
                  font-size: 0.24rem;
                  margin-bottom: 0.12rem;
                ">
                充值金额
              </div>
              <div class="member_tab_1_content">
                <div class="member_tab_1_content_item" :class="index == 0 ? 'member_tab_1_content_item_select' : ''"
                  v-for="(item, index) in 4">
                  50元
                </div>
              </div>
              <div class="member_tab_1_ipt">
                <div class="member_tab_1_ipt_input">
                  <input placeholder="自定义金额" />
                </div>
                <div class="member_tab_1_ipt_bnt">确定</div>
              </div>
            </div>
            <!-- 大额充值 -->
            <div class="member_tab_2" v-if="memberTab == 2">
              <div class="member_tab_2_title">
                <div>
                  <span style="font-size: 0.24rem; color: #333333">折扣类型</span>
                  <span style="
                      color: #86909c;
                      font-size: 0.12rem;
                      margin-left: 0.12rem;
                    ">折扣在购买企业类授权的视频/音乐素材时均生效，有效期为 366
                    天</span>
                </div>
              </div>
              <div class="member_tab_2_content">
                <div v-for="(item, index) in 4" class="member_tab_2_content_item"
                  :class="index == 0 ? 'member_tab_2_content_item_select' : ''">
                  <div>企业授权折扣</div>
                  <div>
                    <span style="color: #333333; font-size: 0.32rem" :style="index == 0 ? 'color:#C01A26 ' : ''">9</span>
                    <span style="color: #333333; font-size: 0.01rem">折</span>
                  </div>
                  <div>
                    单笔充值满<span style="color: #c01a26">1万</span>元开启
                  </div>
                </div>
              </div>
            </div>
            <!-- 扫码部分 -->
            <div class="member_bottom" style="display: flex">
              <div style="width: 1rem; height: 1rem">
                <img style="width: 100%; height: 100%" src="../../assets/LS6yx.png" />
              </div>
              <div class="member_bottom_rigth" style="margin-left: 0.24rem">
                <div>
                  <span>应付金额：</span>
                  <span style="color: #c01a26">￥<span style="font-size: 0.24rem; color: #c01a26">10,000</span></span>
                </div>
                <div>
                  <span style="
                      width: 0.16rem;
                      height: 0.16rem;
                      display: inline-block;
                    ">
                    <img style="width: 100%; height: 100%" src="../../assets/img/weixin.png" />
                  </span>
                  <span style="
                      width: 0.16rem;
                      height: 0.16rem;
                      display: inline-block;
                      margin: 0 0.04rem;
                    ">
                    <img style="width: 100%; height: 100%" src="../../assets/img/zhifubao.png" />
                  </span>
                  <span>使用微信/支付宝扫码支付</span>
                </div>
                <div>
                  支付即表示同意《标识山西会员协议》《标识山西自动续费协议》
                </div>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- 手机导航栏 -->
      <div :class="{ no: isActive }" class="driodiwn_menu">
        <div v-for="index in navList" class="header_nav_title header_nav_title_n" :key="index"
          @click.stop="navClick(index.url, index)">
          <span style="color: #000">{{ index.title }}</span><span style="float: right; color: #86909c">></span>
        </div>
      </div>
    </div>

    <!-- 登录弹窗 -->
    <el-dialog title="" v-model="dialogDelVisible" align-center width="480px" :close-on-click-modal="false">
      <div style="width: 0.4rem; height: 0.4rem; margin: 0 auto">
        <img style="100%;height: 100%;" src="@/assets/img/tips／exclamation-circle-fill@2x.png" />
      </div>

      <div style="text-align: center; font-size: 0.16rem; margin: 0.16rem 0">
        确定要退出登录吗?
      </div>
      <div style="display: flex; justify-content: center">
        <div class="dataBtnClose" @click="dialogDelVisible = false">取消</div>
        <div class="dataBtnSubmit" style="color: #fff; background: #c01a26; margin: 0.2rem 0.1rem" @click="loLoutOk()">
          确认
        </div>
      </div>
    </el-dialog>
  </div>
  <follow ref="FollowRef" />
  <vermicelli ref="VermicelliRef" />
  <details-of-works ref="DetailsOfWorksRef" />
  <el-dialog v-model="show" align-center width="480px" :close-on-click-modal="false" title="系统通知">
    <div style="text-align: center">
      {{ showTitle ? showTitle : "系统通知" }}
    </div>
    <div v-html="showHtml" style="text-align: center; padding-top: 5px; font-size: 14px"></div>
    <!-- <div style="display: flex; justify-content: center">
      <div class="dataBtnClose" @click="show = false">取消</div>
      <div class="dataBtnSubmit" @click="show = false">确认</div>
    </div> -->
  </el-dialog>
</template>
<script>
import { ref, watch, onMounted, onUnmounted, nextTick, computed } from "vue";
import { useRouter } from "vue-router";
import { Axios } from "../../assets/js/ls_com_axios.js";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
// 关注
import Follow from "@/components/Composer/Follow.vue";
// 粉丝`
import com_date from "@/assets/js/common.js";
import Vermicelli from "@/components/Composer/Vermicelli.vue";
import DetailsOfWorks from "@/views/ShadowUser/components/DetailsOfWorks.vue";

export default {
  components: {
    Follow,
    Vermicelli,
    DetailsOfWorks,
  },
  setup(props, context) {
    const FollowRef = ref();
    const VermicelliRef = ref();
    let urlName = ref(window.location.href);
    let NavType = ref(2);
    /*个人信息参数*/
    const nickNameu = ref("");
    const fansNumberu = ref("");
    const workNumber = ref("");
    const followNumberu = ref("");
    const signingNumeru = ref("");
    const avatar = ref("");
    const profess = ref("");
    const store = useStore();
    const meaasgeTitle_list_xttz = computed(() => {
      // console.info(store.state.uploadList);
      return store.state.SystemMessages;
    });
    const navList = ref([
      {
        url: "/",
        title: "首页",
        class: "header_nav_title header_nav_title_y",
      },
      {
        url: "/ShadowIndexAllWorks",
        title: "找案例",
        class: "header_nav_title header_nav_title_n",
      },
      {
        url: "/ShadowComposer",
        title: "找创作人",
        class: "header_nav_title header_nav_title_n",
      },
      {
        url: "/ShadowMaterial",
        title: "找素材",
        class: "header_nav_title header_nav_title_n",
        list: [
          {
            title: "找视频",
            img: require("../../assets/yxsc_sp.png"),
            checked: false,
            id: 2,
            url: "/ShadowMaterial",
            type: "video",
          },
          {
            title: "找音乐",
            img: require("../../assets/yxsc_yy.png"),
            checked: false,
            id: 3,
            url: "/ShadowMaterial",
            type: "music",
          },
          {
            title: "找图片",
            img: require("../../assets/yxsc_tp.png"),
            checked: false,
            id: 1,
            url: "/ShadowMaterial",
            type: "image",
          },
        ],
      },
      {
        url: "/ShadowBusiness",
        title: "找业务",
        class: "header_nav_title header_nav_title_n",
      },
      {
        url: "/ShadowEquipment",
        title: "找器材",
        class: "header_nav_title header_nav_title_n",
      },
      {
        url: "/ShadowTrain",
        title: "培训",
        class: "header_nav_title header_nav_title_n",
      },
      {
        url: "/ShadowActivity",
        title: "活动",
        class: "header_nav_title header_nav_title_n",
        // list: [{
        // 		title: '设计大赛',
        // 		checked: false,
        // 		id: 1,
        // 		url: '/ShadowActivity'
        // 	},
        // 	{
        // 		title: '线上活动',
        // 		checked: false,
        // 		id: 1,
        // 		url: '/ShadowActivity'
        // 	},
        // 	{
        // 		title: '线下活动',
        // 		checked: false,
        // 		id: 1,
        // 		url: '/ShadowActivity'
        // 	}
        // ]
      },
    ]);
    let show = ref(false);
    let showHtml = ref("<p></p>");
    let showTitle = ref(null);
    onMounted(async () => {
      await nextTick();
      init();
      getList();
      // show.value = true;
      setTimeout(function () {
        if (JSON.parse(localStorage.getItem("userList"))) {
          createWebSocket();
        }
      }, 600);
    });
    const detailLinks = ref({
      material_order: { path: "/myOrder" },
      material_order1: { path: "/myOrder" },
      material_order2: { path: "/onSaleMaterial" },
      material_order3: { path: "/requirementPublish" },
      material_order4: { path: "/ShadowBusinessList" },
      material_order5: { path: "/requirementPublish", query: { ccNum: 2 } },
      material_order6: { path: "/requirementPublish" },
      material_order7: { path: "/requirementPublish" },
      need_publish: { path: "/requirementPublish" },
      material_audit_s: { path: "/onSaleMaterial" },
      material_audit_f: { path: "/newTrialMaterial" },
      need_signUp: { path: "/requirementPublish", query: { state: "first1" } },
      need_signBill: { path: "/requirementPublish", query: { state: "first" } },
      need_signRefuse: {
        path: "/requirementPublish",
        query: { state: "four" },
      },
      need_choice: {
        path: "/requirementManagement",
        query: { state: "first" },
      },
      need_pay_A: { path: "/requirementPublish", query: { state: "first" } },
      need_pay_B: {
        path: "/requirementManagement",
        query: { state: "second" },
      },
      need_settlement: {
        path: "/requirementManagement",
        query: { state: "four" },
      },
      need_refundSuccess: {
        path: "/requirementPublish",
        query: { state: "four3" },
      },
      need_refundFailed: {
        path: "/requirementPublish",
        query: { state: "four3" },
      },
      need_confirm: {
        path: "/requirementPublish",
        query: { state: "second" },
      },
      need_finish: {
        path: "/requirementPublish",
        query: { state: "second" },
      },
      work_like: {
        path: "/MessageBarList",
        query: { type: "1" },
      },
      work_favorite: {
        path: "/MessageBarList",
        query: { type: "2" },
      },
      follow: {
        path: "/MessageBarList",
        query: { type: "3" },
      },
      material_collect: {
        path: "/ShadowMaterialVideoParticulars",
        query: { id: "2" },
      },
      system_notice: {
        path: false,
      },
    });
    const DetailsOfWorksRef = ref();
    const DetailsOfWorksClick = (item) => {
      DetailsOfWorksRef.value.init(item.id);
    };
    const goToDetail = (path, query = {}, item) => {
      let arr = [...store.state.SystemMessages];

      function removeObjectById(arr, id) {
        return arr.filter((obj) => obj.noticeId !== id);
      }

      Axios(
        "post",
        `/system/notice/front/one-read/${item.noticeId}`,
        {},
        false,
        function (res) {
          if (!path) {
            showTitle.value = item.noticeTitle;
            showHtml.value = item.noticeContent;
            show.value = true;
            store.commit(
              "SetSystemMessages",
              removeObjectById(arr, item.noticeId)
            );
            init();
            return;
          }
          store.commit(
            "SetSystemMessages",
            removeObjectById(arr, item.noticeId)
          );
          if (query.type) {
            init();
          }

          if (item.code == "material_collect") {
            let extendsData = JSON.parse(item.extendsData);
            let obj = {};

            switch (extendsData.materialType) {
              case 2:
                obj = { url: "/ShadowMaterialVideoParticulars" };
                break;
              case 1:
                obj = { url: "/ShadowMaterialVideoMusic" };
                break;
              case 0:
                obj = { url: "/ShadowMaterialVideoImage" };
                break;
              default:
                // Handle default case
                break;
            }

            if (obj.url) {
              router.push({
                path: obj.url,
                query: {
                  id: extendsData.id,
                },
              });
            }
          } else if (item.code == "work_like" || item.code == "work_favorite") {
            let extendsData = JSON.parse(item.extendsData);
            DetailsOfWorksClick(extendsData);
          } else {
            router.push({
              path,
              query,
            });
          }
        }
      );
    };
    const getList = () => {
      let obj = {
        pageNum: 1,
        pageSize: 6,
        status: 0,
        orderByColumn: "createTime",
        isAsc: "desc",
      };
      Axios("GET", "/system/notice/front/list", obj, false, function (res) {
        res.data.rows.forEach((e) => {
          switch (e.code) {
            case "need_publish":
            case "material_order3":
            case "material_order5":
            case "material_order6":
            case "material_order7":
            case "need_signUp":
            case "need_signBill":
            case "need_signRefuse":
            case "need_pay_A":
            case "need_refundSuccess":
            case "need_refundFailed":
            case "need_confirm":
            case "need_finish":
            case "need_choice":
            case "material_audit_s":
            case "material_audit_f":
            case "need_pay_B":
            case "need_settlement":
              e.noticeTitle = e.noticeContent;
              break;
            case "work_like":
            case "work_favorite":
            case "follow":
              e.avatar1 = e.avatar;
              e.nickName1 = e.nickName;
              e.noticeContent = e.noticeContent || e.noticeTitle;
              break;
            case "material_collect":
              e.avatar1 = e.avatar;
              e.nickName1 = e.nickName;
              // e.noticeContent = e.noticeTitle;
              break;
            case "system_notice":
              // e.avatar1 = e.avatar;
              // e.nickName1 = e.nickName;
              // e.noticeContent = e.noticeTitle;
              break;
            default:
              break;
          }
        });
        res.data.rows.sort(function (a, b) {
          return (
            new Date(b.createTime).getTime() - new Date(a.createTime).getTime()
          );
        });
        store.commit("SetSystemMessages", res.data.rows);

        // meaasgeTitle_list_xttz.value = res.data.rows;
        // meaasgeTitle_list_xttz.value.sort(function (a, b) {
        //   return (
        //     new Date(b.createTime).getTime() - new Date(a.createTime).getTime()
        //   );
        // });
        return;
      });
      // 将时间戳转换为中文格式时间
      function convertToChineseTime(timestamp) {
        const date = new Date(timestamp);

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
    };
    const FollowClick = () => {
      FollowRef.value.init(
        JSON.parse(localStorage.getItem("userList")).memberId
      );
      // setTimeout(() => {
      // console.log(FollowRef.value);
      // }, 500);
    };
    const VermicelliClick = () => {
      // setTimeout(() => {
      //   console.log(VermicelliRef.value);
      // }, 500);
      VermicelliRef.value.init(
        JSON.parse(localStorage.getItem("userList")).memberId
      );
    };
    const init = () => {
      Axios("get", `/member/login-info`, {}, false, (res) => {
        localStorage.setItem("userList", JSON.stringify(res.data.data));
        let data = res.data.data;
        nickNameu.value = data.nickName;
        avatar.value = data.avatar;
        profess.value = data.profess;
        fansNumberu.value = data.fansNumber;
        followNumberu.value = data.followNumber;
        workNumber.value = data.workNumber;
        signingNumeru.value = data.signingNumer;
      });
    };

    const userList = ref(JSON.parse(localStorage.getItem("userList"))) || ref();
    console.log(userList);
    //充值会员
    const topUpMember = ref(false);
    const handTopUpMember = function () {
      if (localStorage.getItem("token")) {
        topUpMember.value = true;
      } else {
        context.emit("TgLoginType", true);
      }
    };
    const memberTab = ref(1);
    const memberTabClick = function (e) {
      memberTab.value = e;
    };
    let loginState = ref(false);
    if (localStorage.getItem("token")) {
      loginState.value = true;
    } else {
      loginState.value = false;
    }
    const setLoginState = function (index) {
      loginState.value = index;
    };
    const dialogDelVisible = ref(false);
    const router = useRouter();
    if (
      router.currentRoute.value.path == "/" ||
      router.currentRoute.value.path == "/ShadowComposer" ||
      router.currentRoute.value.path == "/ShadowMaterial" ||
      router.currentRoute.value.path == "/ShadowBusiness" ||
      router.currentRoute.value.path == "/ShadowEquipment" ||
      // router.currentRoute.value.path == "/ShadowTrain" ||
      router.currentRoute.value.path == "/ShadowIndexAllWorks" ||
      router.currentRoute.value.path == "/AuthenTicationHome"
    ) {
      NavType.value = 2;
    } else {
      NavType.value = 1;
    }
    // 监听顶部样式
    (function () {
      window.addEventListener("scroll", function (e) {
        //alert("滚动了");
        //变量t就是滚动条滚动时，到顶部的距离
        var t = document.documentElement.scrollTop || document.body.scrollTop;
        if (
          router.currentRoute.value.path == "/" ||
          router.currentRoute.value.path == "/ShadowComposer" ||
          router.currentRoute.value.path == "/ShadowMaterial" ||
          router.currentRoute.value.path == "/ShadowBusiness" ||
          router.currentRoute.value.path == "/ShadowEquipment" ||
          // router.currentRoute.value.path == "/ShadowTrain" ||
          router.currentRoute.value.path == "/ShadowIndexAllWorks" ||
          router.currentRoute.value.path == "/AuthenTicationHome"
        ) {
          if (t > 0) {
            NavType.value = 1;
          } else {
            NavType.value = 2;
          }
        }
      });
    })();
    const getNavName = function (url) {
      let arr = navList.value;
      arr.forEach((item) => {
        if (item.url == router.currentRoute.value.path) {
          // item.isActive=true
          item.class = "header_nav_title header_nav_title_y";
        } else {
          item.class = "header_nav_title header_nav_title_n";
        }
      });
      // console.log(212);
      // console.log(router.currentRoute.value.path);
    };
    getNavName();
    // 滚动条发生变化
    watch(
      () => router.currentRoute.value.path,
      (newData, oldData) => {
        // 监听高亮

        if (
          newData == "/" ||
          newData == "/ShadowComposer" ||
          newData == "/ShadowMaterial" ||
          newData == "/ShadowBusiness" ||
          newData == "/ShadowEquipment" ||
          newData == "/ShadowTrain" ||
          newData == "/ShadowIndexAllWorks" ||
          newData == "/ShadowActivity"
        ) {
          // for (let i = 0; i < navList.value.length; i++) {
          //   if (newData == navList.value[i].url) {
          //     navList.value[i].class = "header_nav_title header_nav_title_y";
          //   } else {
          //     navList.value[i].class = "header_nav_title header_nav_title_n";
          //   }
          // }
        }
        getNAV(newData);
        if (newData != oldData) {
          window.scrollTo(0, 0);
          //监听样式
          if (
            newData == "/" ||
            newData == "/ShadowComposer" ||
            newData == "/ShadowMaterial" ||
            newData == "/ShadowBusiness" ||
            newData == "/ShadowEquipment" ||
            // newData == "/ShadowTrain" ||
            newData == "/ShadowIndexAllWorks"
          ) {
            // NavType.value = 2;
          } else {
            NavType.value = 1;
          }
        }
      },
      {
        immediate: false,
        deep: true,
      }
    );
    // 监听导航栏高亮
    const getNAV = function (newData) {
      for (let i = 0; i < navList.value.length; i++) {
        if (navList.value[i].url == newData) {
          navList.value[i].class = "header_nav_title header_nav_title_y";
        } else {
          navList.value[i].class = "header_nav_title header_nav_title_n";
        }
      }
    };
    const my_list = ref([
      {
        title: "购物车",
        img: require("../../assets/grzx_gwc.png"),
        url: "/purchasedCar",
      },
      {
        // 此处需要传值判断
        title: "收藏夹",
        img: require("../../assets/grzx_sc.png"),
        url: "ShadowUser",
        type: "sc",
      },
      {
        title: "我的收益",
        img: require("../../assets/grzx_wdsy.png"),
        url: "/tradingRecord",
      },
      {
        title: "我的素材",
        img: require("../../assets/grzx_wdsc.png"),
        url: "/materialUpload",
      },
      {
        title: "我的订单",
        img: require("../../assets/grzx_wddd.png"),
        url: "/myOrder",
      },

      // {
      // 	title: '担保管理',
      // 	img: require('../../assets/grzx_dbgl.png'),
      // 	url: ''
      // },
      {
        title: "需求管理",
        img: require("../../assets/grzx_xqgl.png"),
        url: "/requirementManagement",
      },
      {
        title: "器材管理",
        img: require("../../assets/grzx_qcgl.png"),
        url: "/materialManagement",
      }, {
        title: "我的课程",
        img: require("../../assets/9.png"),
        url: "/myCourse",
      },
      // {
      //   title: "版权管理",
      //   img: require("../../assets/grzx_bqgl.png"),
      //   url: "/CopyrightPage",
      // },
      {
        title: "认证中心",
        img: require("../../assets/grzx_rzzx.png"),
        url: "/AuthenTicationHome",
      },
      {
        title: "个人设置",
        img: require("../../assets/grzx_grsz.png"),
        url: "/AuthenTication",
      },
      {
        title: "意见反馈",
        img: require("../../assets/grzx_yjfk.png"),
        url: "/feedback",
      },
    ]);
    const AnnouncementList = ref([]);
    const Announcement = () => {
      // Axios("GET", "/system/notice/front/list", { noticeType: 1, status: 0 }, false, function (res) {
      // });
      Axios(
        "post",
        `/material/material/work/listNotice`,
        {
          pageIndex: 1,
          pageSize: 20,
        },
        false,
        (res) => {
          AnnouncementList.value = res.data.data.records;
        }
      );
    };
    Announcement();
    const my_list_click = function (index) {
      if (index.url != "") {
        if (index.type) {
          router.push({
            path: index.url,
            query: { type: index.type },
          });
        } else {
          router.push({
            path: index.url,
          });
        }
        3;
      } else {
        alert("路由未添加...");
      }
      personalSetting_checked.value = false;
    };
    const isActive = ref(true); // 初始化isActive为false

    const navClick = function (url, index) {
      if (index.list) {
        return;
      }
      for (let i = 0; i < navList.value.length; i++) {
        if (url == navList.value[i].url) {
          navList.value[i].class = "header_nav_title header_nav_title_y";
        } else {
          navList.value[i].class = "header_nav_title header_nav_title_n";
        }
      }
      // localStorage.setItem('navList', JSON.stringify(navList.value))
      isActive.value = true;
      context.emit("NavUrlFun", url);
    };
    const navListClick = function (val, index) {
      localStorage.setItem("ShadowMaterial_bgType", val.id);
      router.push({
        path: val.url,
        query: { type: val.type },
      });
      for (let i = 0; i < navList.value.length; i++) {
        if (index == navList.value[i]) {
          navList.value[i].class = "header_nav_title header_nav_title_y";
        } else {
          navList.value[i].class = "header_nav_title header_nav_title_n";
        }
      }
    };
    /* 登录 */
    const loginClick = function () {
      /* 登录状态判断待添加.... */
      /* 未登录的功能 */
      // context.emit("TgLoginType", true);
      router.push({
        path: "/TgLogin",
      });
      /* 已经登录后的展示 */
    };
    // 退出登录 弹窗
    const loLout = function () {
      dialogDelVisible.value = true;
      publick_checked_boor.value = "";
    };
    const loLoutOk = function () {
      Axios("DELETE", "/auth/member/logout", null, false, function (res) {
        if (res.data.code == 200) {
          dialogDelVisible.value = false;
          publick_checked_boor.value = "";
          localStorage.removeItem("token");
          localStorage.removeItem("userList");

          loginState.value = null;
          router.push({
            path: "/TgLogin",
          });
          context.emit("setloginType", false); // 登录状态
          // 根据路由判断退出后是否返回首页
        }
      });
    };
    /* 已登陆 */
    const LoggedClick = function () {
      clearInterval(publickClearTimeout3.value);
      personalSetting_checked.value = true;
      publick_checked_boor.value = "grsz";
    };
    /* 个人设置 */
    const personalSetting_checked = ref("false");

    const LoggedMouseout = function () {
      // userList.value = JSON.parse(localStorage.getItem("userList"));
      // nickNameu.value = userList.value.nickName;
      // avatar.value = userList.value.avatar;
      // console.info(avatar.value);
      // profess.value = userList.value.profess;
      // fansNumberu.value = userList.value.fansNumber;
      // followNumberu.value = userList.value.followNumber;
      // workNumber.value = userList.value.workNumber;
      // signingNumeru.value = userList.value.signingNumer;
      publickClearTimeout3.value = setInterval(function () {
        // console.log(publickClearTimeoutNumber3.value);
        if (publickClearTimeoutNumber3.value >= 0) {
          publickClearTimeoutNumber3.value -= 1;
        } else {
          publickClearTimeoutNumber3.value = 1;
          personalSetting_checked.value = false;
          clearInterval(publickClearTimeout3.value);
        }
      }, 100);
    };
    setTimeout(function () {
      userList.value = JSON.parse(localStorage.getItem("userList"));
    }, 1000);

    /* 上传方式列表 -- 样式显示隐藏 */
    const header_right_uploading_listBoor = ref("");
    const header_right_uploadingMouseover = function () {
      clearInterval(publickClearTimeout2.value);
      header_right_uploading_listBoor.value = true;
      publick_checked_boor.value = "scfs";
    };
    const header_right_uploadingMouseoverList = function () {
      clearInterval(publickClearTimeout2.value);
    };
    const header_right_uploadingMouseout = function () {
      // header_right_uploading_listBoor.value = false
      publickClearTimeout2.value = setInterval(function () {
        if (publickClearTimeoutNumber2.value >= 0) {
          publickClearTimeoutNumber2.value -= 1;
        } else {
          publickClearTimeoutNumber2.value = 1;
          header_right_uploading_listBoor.value = false;
          clearInterval(publickClearTimeout2.value);
        }
      }, 100);
    };
    /* 上传方式列表鼠标事件 */
    const HRU_list = ref([
      {
        title: "上传案例",
        img: require("../../assets/publick_sczuoping.png"),
        checked: false,
        url: "/uploadWorks",
      },
      {
        title: "上传素材",
        img: require("../../assets/publick_scshiping.png"),
        checked: false,
        url: "/materialUpload",
      },
    ]);
    const HRU_listMouseover = function (index) {
      for (let i = 0; i < this.HRU_list.length; i++) {
        if (this.HRU_list[i] == index) {
          this.HRU_list[i].checked = true;
        } else {
          this.HRU_list[i].checked = false;
        }
      }
    };
    const HRU_listMouseout = function (index) {
      for (let i = 0; i < this.HRU_list.length; i++) {
        if (this.HRU_list[i] == index) {
          this.HRU_list[i].checked = false;
        }
      }
    };
    const HRU_listClick = function (index) {
      this.header_right_uploading_listBoor = false;
      if (localStorage.getItem("token")) {
        router.push({
          path: index.url,
        });
      } else {
        context.emit("TgLoginType", true);
        router.push({
          path: "TgLogin",
        });
      }
    };
    /* 消息栏显示状态 */
    const stopPressList = ref("false"); // 消息列表显示状态
    const stopPressChecked = ref("true"); // 是否有最新消息小红标显示状态
    const publickClearTimeout = ref();
    const publickClearTimeoutNumber = ref(1);
    const publickClearTimeout2 = ref();
    const publickClearTimeoutNumber2 = ref(1);
    const publickClearTimeout3 = ref();
    const publickClearTimeoutNumber3 = ref(1);
    const stopPressListMouseleave = function () {
      publickClearTimeout.value = setInterval(function () {
        if (publickClearTimeoutNumber.value >= 0) {
          publickClearTimeoutNumber.value -= 1;
        } else {
          publickClearTimeoutNumber.value = 1;
          stopPressList.value = false;
          clearInterval(publickClearTimeout.value);
        }
      }, 100);
    };
    const stopPressListMouseenter = function () {
      clearInterval(publickClearTimeout.value);
      stopPressList.value = true;
      publick_checked_boor.value = "xxlxs";
    };
    const HRU_list_qbyd = function () {
      // alert("全部已读待完成");
      Axios("post", `/system/notice/front/read`, {}, false, (res) => {
        // store.commit("SetSystemMessages", arr);
        getList();
        ElMessage.success("全部已读成功");
      });
    };
    const HRU_list_ckqb = function () {
      router.push({
        path: "/MessageBarList",
      });
      publick_checked_boor.value = "";
    };
    /* 公共状态 单独显示 */
    const publick_checked_boor = ref("");
    const goUser = function () {
      router.push({
        path: "/ShadowUser",
      });
      publick_checked_boor.value = "";
    }; // 签单跳转需求管理

    const skip = () => {
      router.push({
        path: "requirementManagement",
        query: {
          state: "four",
        },
      });
    };
    // WebSocket
    const websocket = ref(null);
    const receivedMessages = ref([]);

    // WebSocket服务器地址
    const socketUrl = ref("");

    // 创建WebSocket连接
    const createWebSocket = () => {
      socketUrl.value = `ws://api.biaoshisx.com/system-ws/socket/${JSON.parse(localStorage.getItem("userList")).memberId
        }`;
      websocket.value = new WebSocket(socketUrl.value);

      // 监听WebSocket连接成功
      websocket.value.onopen = () => {
        console.log("WebSocket connected");
      };

      // 监听WebSocket接收消息
      websocket.value.onmessage = (event) => {
        console.log("Message received:", JSON.parse(event.data));
        let onj1 = JSON.parse(event.data);
        onj1.createTime = com_date.com_date.timestamp_string(
          JSON.parse(event.data).createTime,
          false,
          "-",
          ":"
        ); // console.log("meaasgeTitle_list_xttz", meaasgeTitle_list_xttz.value);
        let arr = [...meaasgeTitle_list_xttz.value];
        let obj = onj1;
        // obj.isRead = false;
        arr.unshift(obj);
        let arr1 = arr;
        console.log(arr1);
        store.commit("SetSystemMessages", arr1);
      };
      // 在连接关闭后重新连接
      websocket.value.onclose = function (evt) {
        console.log("WebSocket connection closed");
        // 在连接关闭后重新连接
        setTimeout(createWebSocket(), 2000); // 2秒后重新连接
      };
      // 监听WebSocket关闭
      websocket.value.onclose = () => {
        console.log("WebSocket disconnected");
      };
    };

    // 发送消息
    const sendMessage = (message) => {
      if (websocket.value.readyState === WebSocket.OPEN) {
        websocket.value.send(message);
        console.log("Message sent:", message);
      } else {
        console.error("WebSocket is not open");
      }
    };
    //手机栏目点击按钮
    // const isActive = ref(true); // 初始化isActive为false
    // const tooglebtn = document.querySelector('KS')
    // const tooglebtnicon = document.querySelector('')
    // const tooglebtnmen = document.querySelector('')
    const isActiveclick = () => {
      console.log(isActive.value);
      isActive.value = !isActive.value ? true : false;
      console.log(isActive.value);
    };

    // 关闭WebSocket连接
    const closeWebSocket = () => {
      if (websocket.value) {
        websocket.value.close();
      }
    };
    onUnmounted(() => {
      closeWebSocket();
    });
    return {
      show,
      showTitle,
      showHtml,
      isActiveclick,
      isActive,
      websocket,
      receivedMessages,
      sendMessage,
      store,
      detailLinks,
      goToDetail,
      getList,
      FollowRef,
      VermicelliRef,
      avatar,
      urlName,
      my_list,
      my_list_click,
      router,
      navList,
      navClick,
      navListClick,
      loLout,
      loginClick,
      LoggedClick,
      LoggedMouseout,
      header_right_uploading_listBoor,
      header_right_uploadingMouseover,
      header_right_uploadingMouseout,
      header_right_uploadingMouseoverList,
      HRU_list,
      HRU_listMouseover,
      HRU_listMouseout,
      HRU_listClick,
      stopPressList,
      stopPressChecked,
      // stopPressClick,
      stopPressListMouseleave,
      stopPressListMouseenter,
      publickClearTimeout,
      publickClearTimeoutNumber,
      publickClearTimeout2,
      publickClearTimeoutNumber2,
      publickClearTimeout3,
      publickClearTimeoutNumber3,
      HRU_list_qbyd,
      HRU_list_ckqb,
      personalSetting_checked,
      publick_checked_boor,
      loginState,
      setLoginState,
      goUser,
      topUpMember,
      handTopUpMember,
      memberTab,
      memberTabClick,
      dialogDelVisible,
      loLoutOk,
      NavType,
      userList,
      nickNameu,
      fansNumberu,
      followNumberu,
      signingNumeru,
      getNAV,
      workNumber,
      skip,
      FollowClick,
      VermicelliClick,
      DetailsOfWorksRef,
      meaasgeTitle_list_xttz,
      DetailsOfWorksClick,
      DetailsOfWorksRef,
    };
  },
};
</script>

<style scoped lang="less">
@import url(/src/assets/css/com.css);

.newMember_tab {
  overflow: hidden;
  line-height: 0.5rem;
  display: flex;
  justify-content: center;
  background: #fff;
}

.newMember_tab>div {
  width: calc(50% + 0.15rem);
  display: inline-block;
  position: relative;
}

.newMember_tab_title {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newMember_tab_titleS {
  margin-right: 0.12rem;
}

.nm_tab_back1Y {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    background: #fff;
    transform: skewX(15deg);
  }
}

.nm_tab_back1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    background: linear-gradient(180deg, #ffb9a0 0%, #fda789 100%);
    transform: skewX(-15deg);
  }
}

.nm_tab_back2Y {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -0.15rem;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    background: #fff;
    transform: skewX(-15deg);
  }
}

.nm_tab_back2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -0.15rem;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    background: linear-gradient(180deg, #5c4947 0%, #2f2322 100%);
    transform: skewX(15deg);
  }
}

.header_ {
  // max-width: 15.18rem;
  width: 100%;
  padding: 0.14rem 0;
  position: fixed;
  top: 0;
  z-index: 100;
  /* left:calc((100% - 12.8rem)/2); */
}

.header_>div {
  width: 12.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.header_left,
.header_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.header_left_login {
  order: 1;
}

.header_left_login>img {
  width: 1.17rem;
  margin-right: 0.57rem;
}

.header_left_nav {
  order: 2;
  width: 5.77rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header_left_nav>div {
  font-size: 0.15rem;
  padding: 0.08rem 0;
  position: relative;
}

.header_left_nav .nav_m_list:hover {
  display: block;
}

.nav_m_list {
  display: none;
  position: absolute;
  top: 0.3rem;
  left: -0.21rem;
  padding: 0.04rem;
  background: #fff;
}

.nav_m_list img {
  width: 0.24rem;
  margin-right: 0rem;
}

.nav_m_list {
  width: 1.14rem;
  font-size: 0.14rem;
  color: #333333;
}

.nav_m_list>div {
  display: block;
  padding: 0.12rem 0.16rem;
}

.header_nav_title_n {
  color: #333;
  cursor: pointer;
}

.header_nav_title_n .nav_m_list {
  display: none;
}

.header_nav_title_n:hover .nav_m_list {
  display: block;
  z-index: 3;
}

.header_nav_title_y:hover .nav_m_list {
  display: block;
}

.nav_m_list_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav_m_list_box:hover {
  background: #f0f1f1;
}

.header_nav_title_y {
  color: #c01a26;
  border-bottom: 0.02rem solid #c01a26;
}

.header_right {
  align-items: center;
}

.header_right_hycz {
  padding: 0.03rem 0.11rem 0.03rem 0.03rem;
  background: #c01a26;
  border-radius: 0.95rem;
  font-size: 0.11rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.23rem;
}

.header_right_hycz>img {
  width: 0.28rem;
  margin-right: 0.04rem;
}

.header_right_xxts {
  width: 0.19rem;
  margin-right: 0.23rem;
  position: relative;
}

.header_right_xxts_text {
  position: absolute;
  top: 0rem;
  left: 0.12rem;
  background: #f13f3d;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.header_right_login {
  width: 0.34rem;
  height: 0.34rem;
  background: rgba(41, 120, 255, 0.3);
  border-radius: 50%;
  font-size: 0.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c01a26;
  margin-right: 0.23rem;
}

.header_right_uploading {
  padding: 0.08rem 0.38rem;
  border-radius: 0.08rem;
  background: #c01a26;
  color: #fff;
  font-size: 0.13rem;
  position: relative;
}

.header_right_uploading_list {
  position: absolute;
  top: 0.48rem;
  left: -0.87rem;
  padding: 0.04rem;
  background: #fff;
  /* 		width: 1.9rem;
		height: 1rem; */
  box-shadow: 0px 0.04rem 0.11rem 0px rgba(32, 40, 58, 0.25);
  z-index: 9999;
}

.header_right_uploading_list>div {
  display: block;
  /* justify-content: center;
		align-items: center; */
  text-align: center;
  font-size: 0.13rem;
  color: #333;
  padding: 0.13rem 0.49rem;
  /* background: #F0F1F1; */
}

.header_right_uploading_list>div>img {
  width: 0.23rem;
}

.header_right_messageList {
  position: absolute;
  top: 44px;
  left: -253px;
  background: #fff;
  box-shadow: 0px 0.04rem 0.23rem 0px rgba(174, 181, 197, 0.25);
  border-radius: 0.11rem;
  padding: 0.23rem 0.23rem 0 0.23rem;
  z-index: 9999;
  height: 3.79rem;
}

.header_right_messageListUser {
  height: calc(100% - 0.4rem);
  overflow: auto;
  width: 3.4rem;
}

.header_right_messageListUser>template>div {
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.24rem;
  cursor: pointer;
}

.xt-img {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
}

.HRMl_title {
  width: 2.85rem;
}

.HRMl_title_time {
  font-size: 0.11rem;
  color: #86909c;
}

.HRMl_title_title {
  font-size: 0.13rem;
  color: #333333;
  width: 241px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header_right_messageListButton {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.header_right_messageListButton>div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.11rem 0.49rem;
  border-top: 1px solid #e5e6eb;
  width: 100%;
}

.header_right_messageListButton>div>span {
  color: #86909c;
  font-size: 0.13rem;
}

.header_right_login_personalSetting {
  position: absolute;
  top: 0.4rem;
  left: -2.7rem;
  padding: 0.23rem;
  box-shadow: 0px 0.02rem 0.19rem 0px rgba(75, 82, 98, 0.15);
  border-radius: 0.11rem;
  background: #fff;
  z-index: 9999;
}

.HRLPS_t {
  width: 2.58rem;
}

.HRLPS_t,
.HRLPS_tl,
.HRLPS_t_iconList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.HRLPS_t_iconList {
  flex-wrap: wrap;
}

.HRLPS_tl>img {
  width: 0.47rem;
  height: 0.47rem;
  border-radius: 50%;
  margin-right: 0.11rem;
}

.HRLPS_tl_logOut {
  width: 0.19rem;
  height: 0.19rem;
}

.HRLPS_tl_title_name {
  font-size: 0.15rem;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HRLPS_tl_title_occupation {
  color: #86909c;
  font-size: 0.11rem;
}

.HRLPS_t_statistics {
  background: #f2f3f5;
  border-radius: 0.11rem;
  padding: 0.15rem 0.21rem;
  margin-top: 0.23rem;
}

.HRLPS_t_statistics_number {
  font-size: 0.15rem;
  color: #000;
  text-align: center;
}

.HRLPS_t_statistics_title {
  font-size: 0.11rem;
  color: #86909c;
  text-align: center;
  cursor: pointer;
}

.HRLPS_t_iconList {
  margin-top: 0.23rem;
}

.HRLPS_t_iconList>div {
  width: 33.33%;
  margin-bottom: 0.24rem;
}

.HRLPS_t_iconList>div>img {
  width: 0.19rem;
  margin: 0 auto;
  display: block;
}

.HRLPS_t_iconList>div>p {
  font-size: 0.11rem;
  color: #333333;
  text-align: center;
  margin-top: 0.04rem;
}

/* 会员 */
.TopUpMember {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}

/* 充值信息 */
/deep/.member_box .el-dialog__body {
  padding: 0 !important;

  border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
  overflow: hidden;
  background: linear-gradient(153deg, #f8fafb 0%, #ffefe5 100%);
}

/deep/.member_box .el-dialog__header {
  display: none;
}

.member_top {
  // height: 1.22rem;

  background: url("../../assets/hybg.jpg") no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  display: flex;
  padding: 0.3rem;
  box-sizing: border-box;

  .member_top_name {
    font-size: 0.16rem;
    color: #c01a26;
    height: 0.5rem;
    line-height: 0.5rem;
    margin-left: 0.12rem;
  }

  .member_top_line {
    width: 0.02rem;
    height: 0.5rem;
    opacity: 1;
    background: #86909c;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }

  .member_top_price {
    display: flex;
    justify-content: flex-start;

    .member_top_price_item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #2f2322;
      margin-right: 0.4rem;

      div:first-child {
        font-size: 0.14rem;
        color: #5c4947;
      }

      div:last-child {
        font-size: 0.16rem;
        color: #5c4947;
        font-weight: bold;
      }
    }
  }
}

.member_tab {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 0.5rem;

  .member_tab_item {
    width: 50%;
    display: flex;
    justify-content: center;
    line-height: 0.5rem;
    cursor: pointer;
  }

  .member_tab_item {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      background: linear-gradient(180deg, #ffb9a0 0%, #fda789 100%);
      transform: skewX(15deg);
    }
  }

  .member_tab_item_select {
    background: linear-gradient(180deg, #ffb9a0 0%, #fda789 100%);
  }
}

.member_tab_1 {
  width: calc(100% - 0.24rem - 0.24rem);
  margin-left: 0.24rem;
  margin-top: 0.24rem;

  .member_tab_1_content {
    display: flex;
    margin-bottom: 0.24rem;
    justify-content: flex-start;
  }

  .member_tab_1_content_item {
    width: 1.21rem;
    height: 0.6rem;
    line-height: 0.6rem;
    background: #ffffff;
    border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
    opacity: 1;
    text-align: center;
    font-size: 0.24rem;
    border: 0.02rem solid #e5e6eb;
    margin-right: 0.24rem;
  }

  .member_tab_1_content_item_select {
    border: 0.02rem solid #c01a26;
    color: #c01a26;
  }

  .member_tab_1_ipt {
    // width: 5rem;
    display: flex;
    justify-content: flex-start;
    height: 0.53rem;

    .member_tab_1_ipt_input input {
      height: 0.53rem;
      width: 5rem;
      background: #ffffff;
      border: 1px solid #f2f3f5;
      padding: 0.16rem;
      overflow: hidden;
    }

    .member_tab_1_ipt_bnt {
      width: 1rem;
      height: 0.53rem;
      line-height: 0.53rem;
      font-size: 0.14rem;
      color: #fff;
      text-align: center;
      background: #c01a26;
      border-radius: 0rem 0.08rem 0.08rem 0rem;
      opacity: 1;
    }
  }
}

.member_tab_2 {
  width: calc(100% - 0.24rem - 0.24rem);
  margin-left: 0.24rem;

  .member_tab_2_title {
    margin-top: 0.24rem;
  }

  .member_tab_2_content {
    display: flex;
    justify-content: flex-start;
    margin-top: 0.14rem;

    .member_tab_2_content_item {
      width: 1.82rem;
      height: 1.93rem;
      flex-direction: column;
      text-align: center;
      display: flex;
      justify-content: space-around;
      border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
      background: #ffffff;
      margin-right: 0.24rem;
    }

    .member_tab_2_content_item_select {
      border: 1px solid #c01a26;
    }
  }
}

.member_bottom {
  width: calc(100% - 0.24rem - 0.24rem);
  background: #ffffff;
  border-radius: 0.12rem;
  padding: 0.24rem;
  box-sizing: border-box;
  color: #86909c;
  font-size: 0.12rem;
  margin-left: 0.24rem;
  margin-bottom: 0.24rem;
  margin-top: 0.24rem;

  .member_bottom_rigth {
    height: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.nvamo {
  display: none;
}

.no {
  display: none !important;
}

.op {
  display: block !important;
}

// @media screen and (max-width: 750px) {

//   .header_ {
//     background: #FFF !important;
//     position: relative;
//   }

//   .nvamo {
//     display: block;
//     font-size: 20px;
//     color: #000;
//     margin-left: 3%;
//   }

//   .header_>div {
//     width: 100%;
//   }

//   .header_right_uploading {
//     display: none;
//   }

//   .header_left_login {
//     text-align: center;
//   }

//   .header_left_login>img {
//     margin-right: 0;
//     width: 30%;
//     margin: 0 auto;

//   }

//   .header_left_nav {
//     display: none;
//   }

//   .header_right_xxts {
//     display: none;
//   }

//   .driodiwn_menu {
//     // display: none;
//     position: absolute;
//     top: 60px;
//     overflow: hidden;
//     transition: height .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
//     background: #FFF;
//     width: 100%;

//   }

//   .header_nav_title_n {
//     width: 100%;
//     padding: 2em 2em;
//     font-size: 16px;

//   }
// }
</style>
